<template>
  <!-- use in backgroundColor #rrggbbaa -->
  <component
    :is="htmlTag"
    v-if="props.tag"
    :to="props.url"
    class="relative inline-flex max-w-[200px] items-center gap-1.5 truncate rounded-full border px-3.5 py-px font-normal lg:max-w-none"
    :class="tagClassList"
    :style="[
      props.tagDefineColorByBe
        ? {
          color: props.colorHex,
          backgroundColor:
            props.variant === 'new' ? '#fff' : `${props.colorHex}10`,
          borderColor: props.colorHex,
        }
        : '',
    ]"
  >
    <span
      v-if="props.colorHex && !props.tagDefineColorByBe"
      class="inline-block size-2.5 shrink-0 rounded-full"
      :style="{ backgroundColor: props.colorHex }"
    />

    <slot>
      {{ tagName }}
    </slot>

    <UiIcon
      v-if="props.variant === 'specialOffer'"
      name="percent"
      :width="12"
      :height="12"
    />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import UiIcon from '../UiIcon/UiIcon.vue'

interface UiTag {
  tag: string
  variant?: string
  colorHex?: string | null
  showHash?: boolean
  small?: boolean
  tagDefineColorByBe?: boolean
  url?: string | null
}

const props = withDefaults(defineProps<UiTag>(), {
  variant: 'new',
  colorHex: null,
  showHash: false,
  tagDefineColorByBe: false,
  small: false,
  url: null,
})

const classColor = computed(() => {
  switch (props.variant) {
    case 'new':
      return 'border-blue-150 text-blue-150'
    case 'outlet':
      return 'border-blue-100 text-blue-100'
    case 'product':
      return 'border-secondary text-secondary'
    case 'white':
      return 'border-white text-white'
    case 'newM2':
      return 'border-blue-200 bg-blue-200 text-grey'
    case 'bestseller':
      return 'border-blue-450 bg-blue-450 text-grey'
    case 'label':
      return 'border-blue-100 bg-blue-450 text-blue'
    case 'color':
      return 'border-blue-450 text-grey pl-2'
    case 'specialOffer':
      return 'bg-gradient-dark-green border-transparent text-white'
    case 'tagDefineColorByBe':
      return ''
    default:
      return 'border-blue-150 text-blue-150'
  }
})

const tagClassList = computed(() => {
  return [
    classColor.value,
    {
      'cursor-pointer hover:opacity-80': props.url,
      'text-sm': !props.small,
      'text-xs': props.small,
    },
  ]
})

const tagName = computed(() => {
  return `${(props.showHash) ? '#' : ''}${props.tag}`
})

const htmlTag = computed(() => {
  return props.url ? resolveComponent('NuxtLink') : 'span'
})
</script>
